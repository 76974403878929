import { render, staticRenderFns } from "./defaultHeader.vue?vue&type=template&id=04fe38fa&scoped=true"
import script from "./defaultHeader.vue?vue&type=script&lang=js"
export * from "./defaultHeader.vue?vue&type=script&lang=js"
import style0 from "./defaultHeader.vue?vue&type=style&index=0&id=04fe38fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04fe38fa",
  null
  
)

export default component.exports